import { useContext, useEffect } from "react"

import { settingsContext } from "@context/provider"
import PropTypes from "prop-types"

export const usePageSetup = ({
  themeColor,
  navigationColor = "text-gray-darkest",
  displayGeneralForm = true,
  footnote = "",
}) => {
  const [, dispatchSettings] = useContext(settingsContext)

  useEffect(() => {
    dispatchSettings({ type: "SET_CURSOR", payload: { display: false, drag: false } })
    dispatchSettings({ type: "SET_NAVIGATION_COLOR", payload: navigationColor })
    dispatchSettings({ type: "SET_NAVIGATION_BACKGROUND_COLOR", payload: themeColor })
    dispatchSettings({ type: "SET_GENERAL_FORM_DISPLAY", payload: displayGeneralForm })
    dispatchSettings({ type: "SET_FOOTNOTE", payload: footnote })
  }, [dispatchSettings, navigationColor, themeColor, displayGeneralForm])

  return {
    themeColor,
  }
}

usePageSetup.propTypes = {
  themeColor: PropTypes.string.isRequired,
  navigationColor: PropTypes.string,
  displayGeneralForm: PropTypes.bool,
}

import { SubmitButton } from "@components/Button"
import React from "react"
import { useFormSettings } from "./useFormSettings"

export const SalesForm = ({ id, subject, recipient, fieldColor, fn = null }) => {
  const { register, formState, handleSubmit, onSubmit, formSubmitted } = useFormSettings({
    id,
    subject,
    recipient,
    fn,
  })
  const { errors } = formState

  return (
    <form id="sales-form" data-aos="fade-up" onSubmit={handleSubmit(onSubmit)} className="relative">
      <div className="grid-cols-2 lg:grid gap-x-4">
        <div className="flex flex-col font-book text-32 sm:flex-row">
          <div className="flex items-center">Name* </div>
          <div className={`relative field-group sm:ml-5 mt-6 sm:mt-0 ${errors.name ? "error" : "clean"}`}>
            <div className="field-underline bottom-7"></div>
            <label id="name-label" className="sr-only">
              Name
            </label>
            <input
              data-aos-anchor="#sales-form"
              data-aos="input-reveal"
              data-aos-delay="200"
              className={`field text-32 ${fieldColor}`}
              type="text"
              aria-labelledby="name-label"
              {...register("name", { required: true, maxLength: 80 })}
            />
          </div>
        </div>
        <div className="flex flex-col font-book text-32 sm:flex-row lg:pl-6 mt-7 sm:mt-2 lg:mt-0">
          <div className="flex items-center">Email* </div>
          <div className={`field-group sm:ml-5 mt-6 sm:mt-0 ${errors.email ? "error" : "clean"}`}>
            <div className="field-underline bottom-7"></div>
            <label id="email-label" className="sr-only">
              Email
            </label>
            <input
              data-aos-anchor="#sales-form"
              data-aos="input-reveal"
              data-aos-delay="400"
              className={`field text-32 ${fieldColor}`}
              type="email"
              aria-labelledby="email-label"
              {...register("email", {
                required: true,
                pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
            />
          </div>
        </div>
      </div>

      <div className="lg:grid gap-x-4 mt-7 sm:mt-2 lg:mt-5" style={{ gridTemplateColumns: "52% 1fr" }}>
        <div className="flex flex-col font-book text-32 sm:flex-row">
          <div className="flex items-center" style={{ minWidth: "210px" }}>
            Organization
          </div>
          <div className="relative mt-6 field-group clean sm:ml-5 sm:mt-0">
            <div className="field-underline bottom-7"></div>
            <label id="organization-label" className="sr-only">
              Organization
            </label>
            <input
              data-aos-anchor="#sales-form"
              data-aos="input-reveal"
              data-aos-delay="600"
              className={`field text-32 ${fieldColor}`}
              type="text"
              aria-labelledby="organization-label"
              {...register("organization", { maxLength: 80 })}
            />
          </div>
        </div>
        <div className="flex flex-col font-book text-32 sm:flex-row mt-7 sm:mt-2 lg:mt-0 lg:pl-6">
          <div className="flex items-center">Title </div>
          <div className="relative mt-6 field-group sm:ml-5 sm:mt-0 clean">
            <div className="field-underline bottom-7"></div>
            <label id="title-label" className="sr-only">
              Title
            </label>
            <input
              data-aos-anchor="#sales-form"
              data-aos="input-reveal"
              data-aos-delay="800"
              className={`field text-32 ${fieldColor}`}
              type="text"
              aria-labelledby="title-label"
              {...register("title", { maxLength: 80 })}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col font-book text-32 sm:flex-row mt-7 sm:mt-2 lg:mt-5">
        <div className="flex items-center" style={{ minWidth: "200px", maxHeight: "80px" }}>
          Comments
        </div>
        <div className="relative mt-6 field-group sm:ml-5 sm:mt-0 clean" style={{ maxWidth: "100%", height: "100%" }}>
          <div className="field-underline bottom-10"></div>
          <label id="comments-label" className="sr-only">
            Comments
          </label>
          <textarea
            data-aos-anchor="#sales-form"
            data-aos="input-reveal"
            data-aos-delay="1000"
            className={`field text-32 ${fieldColor} pt-4`}
            aria-labelledby="comments-label"
            {...register("comments")}
          />
        </div>
      </div>
      <div className="flex mt-12">
        <div className="ml-auto">
          <SubmitButton
            label="Submit"
            color={`text-gray-darkest ${formState.isDirty}`}
            formSubmitted={formSubmitted}
            disabled={formState.isSubmitting}
          />
        </div>
      </div>
      {formSubmitted && (
        <div className="pt-clamp-8-20">
          <p>Thank you! Your message has been sent. Someone will be in touch shortly.</p>
        </div>
      )}
    </form>
  )
}

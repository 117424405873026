import React, { useEffect, useRef, useCallback, useContext } from "react"
import { CSSTransition } from "react-transition-group"

import { settingsContext } from "@context/provider"

export const Cursor = () => {
  const [settings] = useContext(settingsContext)
  const { cursor } = settings

  const Cursor = useRef(null)

  const setCursorPosition = useCallback((e) => {
    Cursor.current.style.top = e.pageY + "px"
    Cursor.current.style.left = e.pageX + "px"
  }, [])

  useEffect(() => {
    if (cursor.display) {
      window.addEventListener("mousemove", setCursorPosition)
      document.querySelector("body").classList.add("md:cursor-none")
    }
    if (!cursor.display) {
      document.querySelector("body").classList.remove("md:cursor-none")
    }
    return () => window.removeEventListener("mousemove", setCursorPosition)
  }, [cursor.display, setCursorPosition])

  return (
    <CSSTransition in={cursor.drag} timeout={250} classNames="cursor-drag">
      <CSSTransition in={cursor.display} mountOnEnter={true} timeout={750} classNames="cursor">
        <div ref={Cursor} className="hidden sm:flex cursor"></div>
      </CSSTransition>
    </CSSTransition>
  )
}

import parse from "html-react-parser"
import React from "react"
import { Helmet } from "react-helmet"

export function SeoPost({ seo, title = "" }) {
  const fullHead = parse(seo.fullHead)

  console.log("fullhead", seo)

  return (
    <Helmet>
      <title>{title}</title>
      {fullHead}
    </Helmet>
  )
}

/*global gtag*/
import React from "react"
import { useFormSettings } from "./useFormSettings"
import { SubmitButton } from "@components/Button"

const eventPixel = () => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) {
    return
  }
  gtag("event", "contact_us_form_submission", { event_category: "contact_us", event_label: "submit" })
}

export const GeneralDarkForm = ({
  id = "272",
  subject = "General Inquiry",
  recipient = "katiek@jkdesign.com, markm@jkdesign.com",
  fn = eventPixel,
}) => {
  const { register, formState, handleSubmit, onSubmit, formSubmitted } = useFormSettings({ id, subject, recipient, fn })
  const { errors } = formState

  return (
    <form id="general-form" data-aos="fade-up" onSubmit={handleSubmit(onSubmit)} className="relative lg:pr-14">
      <div className="font-book text-form">
        <div className="grid gap-y-4">
          <div className="text-white md:hidden">Hi, my name is</div>
          <div className="grid-cols-10 lg:grid">
            <div className="flex md:grid md:grid-cols-max-1fr-max lg:grid-cols-max-1fr-max md:gap-x-2 lg:col-span-8">
              <div className="hidden text-white md:block">Hi, my name is</div>
              <div
                className={`field-group ml-2 ${errors.name ? "error" : "clean"}`}
                style={{ width: "calc(100% - 0.5rem)" }}
              >
                <div className="field-underline bottom-7 lg:bottom-5"></div>
                <input
                  data-aos-anchor="#general-form"
                  data-aos="input-reveal"
                  data-aos-delay="200"
                  className="field bg-white-dark"
                  type="text"
                  {...register("name", { required: true, maxLength: 80 })}
                />
              </div>
              <div className="hidden text-white lg:block">from</div>
            </div>
          </div>
          <div className="grid-cols-10 lg:grid">
            <div className="flex items-center md:grid md:grid-cols-max-1fr-max lg:grid-cols-1fr-max lg:col-span-9">
              <div className="text-white lg:hidden">from</div>
              <div
                className={`field-group ml-2 ${errors.from ? "error" : "clean"}`}
                style={{ width: "calc(100% - 0.5rem)" }}
              >
                <div className="field-underline bottom-7 lg:bottom-5"></div>
                <input
                  data-aos-anchor="#general-form"
                  data-aos="input-reveal"
                  data-aos-delay="400"
                  className="field bg-white-dark"
                  type="text"
                  {...register("from", { required: true, maxLength: 80 })}
                />
              </div>
              <div className="text-white md:hidden">.</div>
              <div className="hidden text-white md:block lg:hidden">. I would like</div>
              <div className="hidden text-white lg:block">. I would like to chat with JK</div>
            </div>
          </div>
          <div className="text-white md:hidden">I would like to chat with JK about</div>
          <div className="flex md:grid md:grid-cols-max-1fr-max md:gap-x-2 lg:grid-cols-max-1fr-max">
            <div className="hidden text-white md:block lg:hidden">to chat with JK about</div>
            <div className="hidden text-white lg:block">about</div>
            <div
              className={`field-group ml-2 ${errors.about ? "error" : "clean"}`}
              style={{ width: "calc(100% - 0.5rem)" }}
            >
              <div className="field-underline bottom-7 lg:bottom-5"></div>
              <input
                data-aos-anchor="#general-form"
                data-aos="input-reveal"
                data-aos-delay="600"
                className="field bg-white-dark"
                type="text"
                {...register("about", { required: true, maxLength: 80 })}
              />
            </div>
            <div className="lg:hidden">.</div>
            <div className="hidden text-white lg:block">. You can reach me at</div>
          </div>
          <div className="text-white lg:hidden">You can reach me at this email address</div>
          <div className="grid-cols-10 md:grid">
            <div className="flex md:grid md:grid-cols-1fr-min md:col-span-6 lg:grid-cols-max-1fr-max md:gap-x-2 lg:col-span-8">
              <div className="hidden text-white lg:block">this email address</div>
              <div
                className={`field-group ml-2 ${errors.email ? "error" : "clean"}`}
                style={{ width: "calc(100% - 0.5rem)" }}
              >
                <div className="field-underline bottom-7 lg:bottom-5"></div>
                <input
                  data-aos-anchor="#general-form"
                  data-aos="input-reveal"
                  data-aos-delay="800"
                  className="field bg-white-dark"
                  type="email"
                  {...register("email", {
                    required: true,
                    pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                />
              </div>
              <div>.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-12">
        <div className="ml-auto">
          <SubmitButton
            label={"Submit"}
            color={"text-blue-light"}
            formSubmitted={formSubmitted}
            disabled={formState.isSubmitting}
          />
        </div>
      </div>
      {formSubmitted && (
        <div className="text-white pt-clamp-8-20">
          <p>Thank you! Your message has been sent. Someone will be in touch shortly.</p>
        </div>
      )}
    </form>
  )
}

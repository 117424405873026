import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import ArrowRight from "@svg/ui/arrow-right.svg"
import CheckMark from "@svg/ui/check-mark.svg"

export const SubmitButton = ({ className, label, color, formSubmitted, allowSubmit = true, disabled }) => {
  const [isHovered, setHover] = useState(false)

  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`flex items-center ${className} ${
        allowSubmit ? color : "text-teal"
      } sm:hover:text-teal sm:hover:underline transition-all duration-300 ${!allowSubmit && "pointer-events-none"} ${
        formSubmitted && "pointer-events-none"
      }`}
      type="submit"
      disabled={disabled}
    >
      <div className="font-bold">{disabled ? 'Processing...' : label}</div>
      {formSubmitted ? (
        <div className="flex ml-2 fill-current" style={{ width: "20px" }}>
          <CheckMark />
        </div>
      ) : (
        <CSSTransition in={isHovered} timeout={300} classNames="button-arrow">
          <div className="flex ml-2 fill-current" style={{ width: "20px" }}>
            <ArrowRight />
          </div>
        </CSSTransition>
      )}
    </button>
  )
}

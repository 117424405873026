/*global gtag*/
import React, { useEffect } from "react"
import { useFormSettings } from "./useFormSettings"
import { useUpload } from "./useUpload"
import { FileUploadButton, SubmitButton } from "@components/Button"

const eventPixel = () => {
  const isBrowser = typeof window !== "undefined"
  if (!isBrowser) {
      return
  }
  gtag('event', 'job_application_submission', { 'event_category': 'apply_now', 'event_label': 'job_application' })
}

export const CareerForm = ({ id, subject, recipient, fn = eventPixel }) => {
  const { register, formState, handleSubmit, onSubmit, formSubmitted, setValue } = useFormSettings({
    id,
    subject,
    recipient,
    fn
  })
  const { file, handleFileChange, removeFile } = useUpload({ name: "resume", setValue })
  const { errors } = formState

  useEffect(() => {
    register("resume", { required: true })
  }, [register])

  useEffect(() => {
    if (formSubmitted) {
      removeFile()
    }
  }, [formSubmitted]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form id="career-form" data-aos="fade-up" onSubmit={handleSubmit(onSubmit)} className="relative">
      <div className="grid-cols-2 lg:grid gap-x-4">
        <div className="flex flex-col font-book text-32 sm:flex-row">
          <div className="flex items-center">Name* </div>
          <div className={`relative field-group sm:ml-5 mt-6 sm:mt-0 ${errors.name ? "error" : "clean"}`}>
            <div className="field-underline bottom-7"></div>
            <input
              data-aos-anchor="#career-form"
              data-aos="input-reveal"
              data-aos-delay="200"
              className="field text-32 bg-pink-light"
              type="text"
              {...register("name", { required: true, maxLength: 80 })}
            />
          </div>
        </div>
        <div className="flex flex-col font-book text-32 sm:flex-row lg:pl-6 mt-7 sm:mt-2 lg:mt-0">
          <div className="flex items-center">Email* </div>
          <div className={`field-group sm:ml-5 mt-6 sm:mt-0 ${errors.email ? "error" : "clean"}`}>
            <div className="field-underline bottom-7"></div>
            <input
              data-aos-anchor="#career-form"
              data-aos="input-reveal"
              data-aos-delay="400"
              className="field text-32 bg-pink-light"
              type="email"
              {...register("email", {
                required: true,
                pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
            />
          </div>
        </div>
      </div>

      <div className="lg:grid gap-x-4 mt-7 sm:mt-2 lg:mt-5" style={{ gridTemplateColumns: "52% 1fr" }}>
        <div className="flex flex-col font-book text-32 sm:flex-row">
          <div className="flex items-center" style={{ minWidth: "210px" }}>
            Portfolio URL{" "}
          </div>
          <div className="relative mt-6 field-group clean sm:ml-5 sm:mt-0">
            <div className="field-underline bottom-7"></div>
            <input
              data-aos-anchor="#career-form"
              data-aos="input-reveal"
              data-aos-delay="600"
              className="field text-32 bg-pink-light"
              type="text"
              {...register("url", { maxLength: 80 })}
            />
          </div>
        </div>
        <div className="flex flex-col font-book text-32 sm:flex-row mt-7 sm:mt-2 lg:mt-0 lg:pl-6">
          <div className="flex items-center">Linkedin* </div>
          <div className={`relative field-group sm:ml-5 mt-6 sm:mt-0 ${errors.linkedin ? "error" : "clean"}`}>
            <div className="field-underline bottom-7"></div>
            <input
              data-aos-anchor="#career-form"
              data-aos="input-reveal"
              data-aos-delay="800"
              className="field text-32 bg-pink-light"
              type="text"
              {...register("linkedin", { required: true, maxLength: 80 })}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col font-book text-32 sm:flex-row mt-7 sm:mt-2 lg:mt-5">
        <div className="flex items-center" style={{ minWidth: "200px", maxHeight: "80px" }}>
          Cover Letter
        </div>
        <div
          className={`relative field-group sm:ml-5 mt-6 sm:mt-0 ${errors.cover ? "error" : "clean"}`}
          style={{ maxWidth: "100%", height: "100%" }}
        >
          <div className="field-underline bottom-10"></div>
          <textarea
            data-aos-anchor="#career-form"
            data-aos="input-reveal"
            data-aos-delay="1000"
            className="pt-4 field text-32 bg-pink-light"
            {...register("cover")}
          />
        </div>
      </div>
      <div className="flex mt-12">
        <div className="flex ml-auto">
          <div className="flex" onChange={handleFileChange}>
            <FileUploadButton label="Upload resume" file={file} removeFile={removeFile} />
          </div>
          <div className="ml-15">
            <SubmitButton
              label="Submit"
              color={`text-gray-darkest ${formState.isDirty}`}
              formSubmitted={formSubmitted}
              allowSubmit={file.set}
              disabled={formState.isSubmitting}
            />
          </div>
        </div>
      </div>
      {formSubmitted && (
        <div className="pt-clamp-8-20">
          <p>Thank you! Your message has been sent. Someone will be in touch shortly.</p>
        </div>
      )}
    </form>
  )
}

import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const ZigZagBlock = ({ editor, image, flow }) => {
  function Caption() {
    return image.caption && <caption className="flex justify-center mt-2 text-16 font-book">{image.caption}</caption>
  }
  return (
    <div className="grid-cols-10 article-text-block lg:grid mt-clamp-18-22">
      <div
        className={`col-start-2 col-span-8 flex ${
          flow === "image_copy" ? "flex-col" : "flex-col-reverse"
        } md:grid grid-cols-8 gap-x-4`}
      >
        {flow === "image_copy" && (
          <>
            <div className="col-span-3">
              <GatsbyImage image={getImage(image.localFile)} loading="true" alt={image.altText} />
              <Caption />
            </div>
            <div className="col-span-5 mt-16 md:mt-0">
              <div className="w-full md:pl-12" dangerouslySetInnerHTML={{ __html: editor }} />
            </div>
          </>
        )}
        {flow === "copy_image" && (
          <>
            <div className="col-span-5 mt-16 md:mt-0">
              <div className="w-full md:pr-12" dangerouslySetInnerHTML={{ __html: editor }} />
            </div>
            <div className="col-span-3">
              <GatsbyImage image={getImage(image.localFile)} loading="true" alt={image.altText} />
              <Caption />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

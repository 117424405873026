import React from "react"

import { TextBlock, ImageBlock, VideoBlock, QuoteBlock, ZigZagBlock, Button } from "@components/Post"

export const PostLayout = (props) => {
  const { layoutData } = props
  const { fieldGroupName: layoutType } = layoutData

  const Default = () => <div>Component is missing: {layoutType}</div>

  const layouts = {
    post_Article_ArticleComponents_TextBlock: TextBlock,
    post_Article_ArticleComponents_ImageBlock: ImageBlock,
    post_Article_ArticleComponents_VimeoBlock: VideoBlock,
    post_Article_ArticleComponents_QuoteBlock: QuoteBlock,
    post_Article_ArticleComponents_ZigZagBlock: ZigZagBlock,
    post_Article_ArticleComponents_Button: Button,
    post_default: Default,
  }

  const ComponentTag = layouts[layoutType] ? layouts[layoutType] : layouts["post_default"]

  return <ComponentTag {...layoutData} />
}

import React, { useContext } from "react"
import { Link } from "gatsby"
import { settingsContext } from "@context/provider"
import LinkedIn from "@svg/social/linkedin.svg"
import Instagram from "@svg/social/instagram.svg"
import Calendly from "@svg/social/calendly.svg"

export const MobileNavigation = ({ isMobileOpen }) => {
  const [settings, dispatchSettings] = useContext(settingsContext)
  const { backgroundColor } = settings.navigation

  const closeMenu = () => {
    dispatchSettings({ type: "SET_MOBILE_MENU", payload: false })
  }

  let textColor = "text-white"
  textColor = backgroundColor === "bg-white-dark" ? "text-gray-dakest" : "text-white"

  return (
    <div className={`fixed flex flex-col ${backgroundColor} inset-0 z-50 lg:hidden`}>
      <div className="w-full" style={{ height: "78px" }}></div>
      <div className="container flex flex-col h-full pt-clamp-14-30 pb-clamp-8-11">
        <nav
          className={`header-navigation grid grid-cols-1 gap-y-clamp-11-15 font-bold text-mobileNavigation ${textColor}`}
        >
          <div data-aos="fade-up" data-aos-duration="500">
            <Link to="/work" activeClassName="active" onClick={() => closeMenu()}>
              Work
            </Link>
          </div>
          <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="50">
            <Link to="/what-we-do" activeClassName="active" onClick={() => closeMenu()}>
              What we do
            </Link>
          </div>
          <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
            <Link to="/about-us" activeClassName="active" onClick={() => closeMenu()}>
              About us
            </Link>
          </div>
          <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
            <Link to="/ideas-and-insights" activeClassName="active" onClick={() => closeMenu()}>
              Ideas and insights
            </Link>
          </div>
          <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="100">
            <Link to="/careers" activeClassName="active" onClick={() => closeMenu()}>
              Careers
            </Link>
          </div>
          <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="150">
            <Link to="/contact" activeClassName="active" onClick={() => closeMenu()}>
              Contact
            </Link>
          </div>
        </nav>
        <div className="flex justify-between mt-auto">
          <div className="flex items-center mt-auto gap-x-6" style={{ height: "23px" }}>
            <a
              href="https://linkedin.com/company/jk-design/mycompany/"
              target="_blank"
              rel="noreferrer"
              className={`fill-current flex justify-center ${textColor}`}
            >
              <LinkedIn />
            </a>
            <a
              href="https://www.instagram.com/jk.design/"
              target="_blank"
              rel="noreferrer"
              className={`fill-current flex justify-center ${textColor}`}
            >
              <Instagram />
            </a>
          </div>
          <div>
            <a
              href="https://calendly.com/chrisbjkdesign"
              target="_blank"
              rel="noreferrer"
              className={`fill-current ${textColor}`}
            >
              <Calendly />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useContext } from "react"

import { settingsContext } from "@context/provider"
import JkLogo from "@svg/jk-logo.svg"
import Exit from "@svg/ui/exit.svg"
import Hamburger from "@svg/ui/hamburger.svg"
import { Link } from "gatsby"
import { DesktopNavigation } from "./DesktopNavigation"
import { MobileNavigation } from "./MobileNavigation"
import { useHeaderScroll } from "./useHeaderScroll"
import { useMobileNav } from "./useMobileNavigation"

export const Header = () => {
  const [settings, dispatchSettings] = useContext(settingsContext)
  const { navigation } = settings
  const { isSmall } = useHeaderScroll()
  const { isMobileOpen } = useMobileNav()

  return (
    <div className={`${navigation.color} overflow-hidden`}>
      {isMobileOpen && <MobileNavigation isMobileOpen={isMobileOpen} />}
      <header className="fixed inset-x-0 top-0 z-60">
        <div
          className={`w-full transition-all duration-500 ${
            isSmall && !isMobileOpen ? navigation.backgroundColor : "bg-transparent"
          }`}
          style={{ padding: isSmall ? "1.25rem 0" : "1.75rem 0" }}
        >
          <div className="container flex items-center justify-between h-full">
            <div>
              <Link to="/">
                <JkLogo />
              </Link>
            </div>
            <div className="cursor-pointer lg:hidden ">
              {isMobileOpen ? (
                <button
                  title="Close mobile navigation"
                  className="flex items-center justify-center"
                  style={{ width: "30px", height: "30px" }}
                  onClick={() => dispatchSettings({ type: "SET_MOBILE_MENU", payload: false })}
                >
                  <div style={{ width: "14px" }}>
                    <Exit />
                  </div>
                </button>
              ) : (
                <button
                  title="Open mobile navigation"
                  className="flex items-center justify-center"
                  style={{ width: "30px", height: "30px" }}
                  onClick={() => dispatchSettings({ type: "SET_MOBILE_MENU", payload: true })}
                >
                  <div style={{ width: "19px" }}>
                    <Hamburger />
                  </div>
                </button>
              )}
            </div>
            <DesktopNavigation />
          </div>
        </div>
      </header>
    </div>
  )
}

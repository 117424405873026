import { useEffect, useContext } from "react"
import { settingsContext } from "@context/provider"

export const useMobileNav = () => {
  const [settings] = useContext(settingsContext)
  const { mobileMenu } = settings
  const { open } = mobileMenu

  useEffect(() => {
    if (open) {
      document.querySelector("body").classList.add("overflow-hidden", "lg:overflow-auto")
    }
    if (!open) {
      document.querySelector("body").classList.remove("overflow-hidden", "lg:overflow-auto")
    }
  }, [mobileMenu, open])

  return {
    isMobileOpen: open,
  }
}

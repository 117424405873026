import React from "react"
import { Link } from "gatsby"

export const DesktopNavigation = () => {
  return (
    <nav className="hidden font-bold header-navigation lg:flex text-16">
      <Link to="/work" activeClassName="active">
        Work
      </Link>
      <Link to="/what-we-do" activeClassName="active" className="ml-16">
        What we do
      </Link>
      <Link to="/about-us" activeClassName="active" className="ml-16">
        About us
      </Link>
      <Link to="/ideas-and-insights" activeClassName="active" className="ml-16">
        Ideas and insights
      </Link>
      <Link to="/careers" activeClassName="active" className="ml-16">
        Careers
      </Link>
      <Link to="/contact" activeClassName="active" className="ml-16">
        Contact
      </Link>
    </nav>
  )
}

import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const ImageBlock = ({ image, caption }) => {
  return (
    <>
      <div className="mt-clamp-18-22">
        <GatsbyImage className="w-full h-full" image={getImage(image.localFile)} loading="true" alt={image.altText} />
      </div>
      {caption !== null && (
        <div className="lg:grid grid-cols-10 mt-6">
          <div className="col-start-2 col-span-8">
            <p className="font-semi text-clamp-14-16">{caption}</p>
          </div>
        </div>
      )}
    </>
  )
}

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import salesData from "@json/sales.json"

export const Sales = ({ client }) => {
  const data = useStaticQuery(query)
  const { name, title, description } = salesData[client]

  return (
    <div className="w-full grid grid-cols-9">
      <div className="col-span-8" style={{ cursor: "none" }}>
        <div className="relative aspect-w-326 aspect-h-364 md:aspect-w-964 md:aspect-h-420">
          <div className="absolute inset-0 pointer-events-none">
            <div className="image-fill hidden md:block">
              <GatsbyImage
                objectFit="contain"
                loading="eager"
                image={getImage(data[`${client}Desktop`])}
                alt={client}
              />
            </div>
            <div className="image-fill md:hidden">
              <GatsbyImage objectFit="contain" loading="eager" image={getImage(data[`${client}Mobile`])} alt={client} />
            </div>
          </div>
        </div>
        <div className="mt-12">
          <h3 className="text-clamp-26-32 font-semi">{name}</h3>
          <p className="font-semi mt-4">{title}</p>
          <p className="text-clamp-16-18" style={{ marginTop: "1rem" }}>
            {description}
          </p>
        </div>
      </div>
    </div>
  )
}

const query = graphql`
  query {
    signifyDesktop: file(relativePath: { eq: "sales-enablement/signify-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    signifyMobile: file(relativePath: { eq: "sales-enablement/signify-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    heinekenDesktop: file(relativePath: { eq: "sales-enablement/heineken-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    heinekenMobile: file(relativePath: { eq: "sales-enablement/heineken-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adDesktop: file(relativePath: { eq: "sales-enablement/ad-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    adMobile: file(relativePath: { eq: "sales-enablement/ad-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lgDesktop: file(relativePath: { eq: "sales-enablement/lg-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lgMobile: file(relativePath: { eq: "sales-enablement/lg-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    eraDesktop: file(relativePath: { eq: "sales-enablement/era-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    eraMobile: file(relativePath: { eq: "sales-enablement/era-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lumendiDesktop: file(relativePath: { eq: "branding/lumendi-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    lumendiMobile: file(relativePath: { eq: "branding/lumendi-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ansDesktop: file(relativePath: { eq: "branding/ans-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    ansMobile: file(relativePath: { eq: "branding/ans-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    spectrumDesktop: file(relativePath: { eq: "branding/spectrum-desktop.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    spectrumMobile: file(relativePath: { eq: "branding/spectrum-mobile.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`

import React, { useContext } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { settingsContext } from "@context/provider"

export const Team = ({ index, member }) => {
  const { title: name, profile } = member
  const [settings, dispatchSettings] = useContext(settingsContext)
  const { personDetail } = settings

  const displayDetails = () => {
    dispatchSettings({ type: "SET_CURSOR", payload: { display: false, drag: false } })
    dispatchSettings({ type: "SET_PERSON_DETAIL_MEMBER", payload: member })
    dispatchSettings({ type: "SET_PERSON_DETAIL_DISPLAY", payload: true })
  }

  return (
    <div className="inline">
      <div data-aos="fade-up" data-aos-delay={200 * index} className="relative overflow-hidden h-auto w-full">
        <button className="w-39 sm:h-124 sm:w-82 lg:h-177 lg:w-117 zoom" onClick={() => displayDetails()}>
          <div className="inset-0 sm:zoom-image">
            <GatsbyImage
              image={getImage(profile.profile.localFile)}
              alt={name}
              className="object-cover pointer-events-none"
            />
          </div>
          {personDetail.display && <div className="absolute inset-0 bg-white opacity-75"></div>}
        </button>
        <div className={`mt-clamp-5-15 w-39 sm:w-full ${personDetail.display && "opacity-0"}`}>
          <h3 className="text-clamp-16-32">{name}</h3>
          <h4 className="font-book font-normal text-clamp-14-22 mt-1 md:mt-4">{profile.title}</h4>
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { ButtonStatic } from "@components/Button"

const WebinarPost = ({ post, index }) => {
  const { title, slug, excerpt, categories, webinar, featuredImage } = post

  return (
    <div className="grid w-full grid-cols-12">
      <Link
        to={`/webinar/${slug}`}
        className="col-span-11 carousel-lg:col-span-12 carousel-lg:mr-20"
        style={{ cursor: "none" }}
      >
        <div className="aspect-w-303 aspect-h-742 md:aspect-w-616 md:aspect-h-742 carousel-lg:aspect-w-1436 carousel-lg:aspect-h-920">
          <div
            className={`flex ${
              index % 2 === 0 ? "flex-col" : "flex-col-reverse"
            } carousel-lg:flex carousel-lg:flex-row`}
          >
            <div
              className={`${webinar.theme} flex-1 relative py-16 sm:h-1/2 carousel-lg:h-full carousel-lg:w-2/5 overflow-hidden z-10`}
            >
              <div className="flex items-center justify-center w-full h-full md:h-full">
                <div className="flex flex-col w-10/12 h-full text-white lg:h-auto carousel-lg:w-9/12">
                  <p className="font-bold underline text-14">{categories.nodes[0].name}</p>
                  <h3 className="mt-6 text-clamp-20-32 md:hover:underline">{title}</h3>
                  <p className="text-clamp-14-18" style={{ marginTop: "1rem" }}>
                    {excerpt}
                  </p>
                  <div className="mt-8">
                    <h3 className="font-bold text-14">Our thought leaders:</h3>
                    <div className="flex mt-2">
                      {webinar.author.map((author, index) => {
                        return (
                          <div
                            className={`rounded-full overflow-hidden ${index !== 0 && "ml-3"}`}
                            key={index}
                            style={{ width: "3.75rem", height: "3.75rem" }}
                          >
                            <GatsbyImage
                              style={{ transform: "translateY(-0.5rem)" }}
                              image={getImage(author.profile.profile.localFile)}
                              alt={author.title}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div className="mt-auto md:mt-5 carousel-lg:mt-12">
                    <ButtonStatic className="text-white">Learn more</ButtonStatic>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative w-full pointer-events-none aspect-w-303 aspect-h-254 md:aspect-none md:h-1/2 carousel-lg:h-full carousel-lg:w-3/5">
              <div className="absolute inset-0 md:h-full bg-blue">
                {featuredImage !== null && (
                  <GatsbyImage className="w-full h-full" image={getImage(featuredImage.node.localFile)} alt={title} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export const MemoizedWebinarPost = React.memo(WebinarPost)

import React from "react"
import { FilterPostDropdownSelector, FilterTypeDropdownSelector, Post, useBlog } from "@components/Blog"
import { ButtonAction } from "@components/Button"

export const Blog = ({ posts, category, type }) => {
  const { postsToShow, showMorePosts } = useBlog({ posts })

  return (
    <>
      <div className="container relative">
        <div className="absolute inset-0 flex">
          <div
            className={`w-full mt-auto ${
              posts?.length < 3 ? "h-1/4" : "aspect-w-1436 aspect-h-400"
            } bg-white-dark extend before:bg-white-dark after:bg-white-dark`}
          ></div>
        </div>
        <div className="flex">
          <div className="flex w-full md:ml-auto md:w-4/12">
            <div className="w-7/12">
              <FilterPostDropdownSelector currentCategory={category} />
            </div>
            <div className="w-5/12">
              <FilterTypeDropdownSelector currentType={type} />
            </div>
          </div>
        </div>
        <div className="relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-4 gap-y-clamp-12-24 mt-15">
          {postsToShow?.map((post, index) => {
            return <Post key={index} post={post.node} />
          })}
        </div>
        {postsToShow.length === posts.length ? null : <button onClick={() => showMorePosts()}>load more</button>}
      </div>
      <div className="bg-white-dark pt-clamp-17-28 pb-clamp-37-64">
        {postsToShow.length !== posts.length && (
          <div className="container">
            <div className="flex">
              <div className="ml-auto sm:w-3/12">
                <ButtonAction func={showMorePosts} className="text-gray-darkest">
                  Load more
                </ButtonAction>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

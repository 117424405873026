import React from "react"

import { ImageBlock } from "@components/Post"

export const PostPostLayout = (props) => {
  const { layoutData } = props
  const { fieldGroupName: layoutType } = layoutData

  const Default = () => <div>Component is missing: {layoutType}</div>

  const layouts = {
    post_Article_ArticlePostComponents_ImageBlock: ImageBlock,
    post_default: Default,
  }

  const ComponentTag = layouts[layoutType] ? layouts[layoutType] : layouts["post_default"]

  return <ComponentTag {...layoutData} />
}

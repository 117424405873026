import Ad from "@svg/client/ad.svg"
import Adp from "@svg/client/adp.svg"
import AirGas from "@svg/client/airgas.svg"
import Amazon from "@svg/client/amazon.svg"
import Amerlux from "@svg/client/amerlux.svg"
import Amgen from "@svg/client/amgen.svg"
import Ans from "@svg/client/ans.svg"
import Basf from "@svg/client/basf.svg"
import BestBuy from "@svg/client/bestbuy.svg"
import Bpm from "@svg/client/bpm.svg"
import Cat from "@svg/client/cat.svg"
import Caterpillar from "@svg/client/caterpillar.svg"
import Centrica from "@svg/client/centrica.svg"
import CheesecakeFactory from "@svg/client/cheesecake-factory.svg"
import Children from "@svg/client/children.svg"
import Compass from "@svg/client/compass.svg"
import Corcoran from "@svg/client/corcoran.svg"
import Csl from "@svg/client/csl.svg"
import Dupont from "@svg/client/dupont.svg"
import Elementis from "@svg/client/elementis.svg"
import Era from "@svg/client/era.svg"
import Hammond from "@svg/client/hammond.svg"
import Heineken from "@svg/client/heineken.svg"
import JandJ from "@svg/client/j-and-j.svg"
import Jj from "@svg/client/jj.svg"
import Kenvue from "@svg/client/kenvue.svg"
import Leb from "@svg/client/leb.svg"
import Lg from "@svg/client/lg.svg"
import Lumendi from "@svg/client/lumendi.svg"
import Marcos from "@svg/client/marcos.svg"
import Merck from "@svg/client/merck.svg"
import Netflix from "@svg/client/netflix.svg"
import Pepsico from "@svg/client/pepsico.svg"
import Philips from "@svg/client/philips.svg"
import Priceline from "@svg/client/priceline.svg"
import Prudential from "@svg/client/prudential.svg"
import Quest from "@svg/client/quest.svg"
import Republic from "@svg/client/republic.svg"
import Ricoh from "@svg/client/ricoh.svg"
import Sani from "@svg/client/sani.svg"
import Santander from "@svg/client/santander.svg"
import Signify from "@svg/client/signify.svg"
import Spectrum from "@svg/client/spectrum.svg"
import Stryker from "@svg/client/stryker.svg"
import Tiffany from "@svg/client/tiffany.svg"
import Unilog from "@svg/client/unilog.svg"
import Wb from "@svg/client/wb.svg"
import Weichert from "@svg/client/weichert.svg"
import Zumtobel from "@svg/client/zumtobel.svg"
import PropTypes from "prop-types"
import React from "react"

export const LogoClient = ({ slug }) => {
  switch (slug) {
    case "ad":
      return <Ad />
    case "adp":
      return <Adp />
    case "airgas":
      return <AirGas />
    case "amazon":
      return <Amazon />
    case "amerlux":
      return <Amerlux />
    case "amgen":
      return <Amgen />
    case "ans":
      return <Ans />
    case "basf":
      return <Basf />
    case "bestbuy":
      return <BestBuy />
    case "bpm":
      return <Bpm />
    case "caterpillar":
      return <Caterpillar />
    case "cat":
      return <Cat />
    case "centrica":
      return <Centrica />
    case "corcoran":
      return <Corcoran />
    case "compass":
      return <Compass />
    case "cheesecake":
      return <CheesecakeFactory />
    case "children":
      return <Children />
    case "csl":
      return <Csl />
    case "dupont":
      return <Dupont />
    case "elementis":
      return <Elementis />
    case "era":
      return <Era />
    case "hammond":
      return <Hammond />
    case "heineken":
      return <Heineken />
    case "jj":
      return <Jj />
    case "j-and-j":
      return <JandJ />
    case "kenvue":
      return <Kenvue />
    case "leb":
      return <Leb />
    case "lg":
      return <Lg />
    case "lumendi":
      return <Lumendi />
    case "marcos":
      return <Marcos />
    case "merck":
      return <Merck />
    case "netflix":
      return <Netflix />
    case "pepsico":
      return <Pepsico />
    case "philips":
      return <Philips />
    case "priceline":
      return <Priceline />
    case "prudential":
      return <Prudential />
    case "quest":
      return <Quest />
    case "republic":
      return <Republic />
    case "ricoh":
      return <Ricoh />
    case "sani":
      return <Sani />
    case "santander":
      return <Santander />
    case "signify":
      return <Signify />
    case "spectrum":
      return <Spectrum />
    case "stryker":
      return <Stryker />
    case "tiffany":
      return <Tiffany />
    case "unilog":
      return <Unilog />
    case "wb":
      return <Wb />
    case "weichert":
      return <Weichert />
    case "zumtobel":
      return <Zumtobel />
    default:
      return null
  }
}

LogoClient.propTypes = {
  slug: PropTypes.string.isRequired,
}

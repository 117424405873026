import React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { Listbox } from "@headlessui/react"
import ArrowDown from "@svg/ui/arrow-down.svg"

export const FilterPostDropdownSelector = ({ currentCategory }) => {
  const categories = [{ node: { name: "All", slug: "all" } }, ...useStaticQuery(query).categories.edges]

  const changeHandler = (slug) => {
    if (slug !== "all") {
      navigate(`/category/${slug}`)
      return
    }
    navigate(`/ideas-and-insights`)
  }

  return (
    <Listbox value={currentCategory} onChange={changeHandler}>
      {({ open }) => (
        <>
          <div className="relative mt-1">
            <Listbox.Button className="w-full px-4 py-5 bg-transparent">
              <div className="flex items-center justify-between w-full">
                <span className="font-bold text-16 text-white-dark">{currentCategory.name}</span>
                <span
                  className={`flex items-center h-full fill-current text-white-dark ${open && "transform rotate-180"}`}
                  style={{ width: "12px" }}
                >
                  <ArrowDown />
                </span>
              </div>
              <div className={`${!open && "bg-white-dark"} w-full h-px`}></div>
            </Listbox.Button>
            <Listbox.Options
              as="div"
              className="absolute inset-x-0 z-10 text-white bg-gray-dark"
              style={{ marginTop: "-1px" }}
            >
              <div className="grid grid-cols-1 px-4 pb-5 gap-y-2">
                {categories.map((category, index) => (
                  <Listbox.Option
                    as="span"
                    key={index}
                    value={category.node.slug}
                    className="inline-block cursor-pointer text-16 text-white-dark md:hover:text-teal"
                  >
                    {category.node.name}
                  </Listbox.Option>
                ))}
              </div>
            </Listbox.Options>
          </div>
        </>
      )}
    </Listbox>
  )
}

const query = graphql`
  query {
    categories: allWpCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`

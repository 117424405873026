import React, { useContext } from "react"
import { CSSTransition } from "react-transition-group"
import { settingsContext } from "@context/provider"

export const CursorMarker = ({ children }) => {
  return <div className="hidden md:block absolute inset-0 z-10 pointer-events-none">{children}</div>
}

const Cursor = ({ className = "bg-gray-darkest after:text-white" }) => {
  const [settings] = useContext(settingsContext)
  const { cursor } = settings
  return (
    <div className="ml-auto swipe-marker-wrapper" data-aos="swipe-marker-wrapper" data-aos-duration="300">
      <CSSTransition in={cursor.display} timeout={300} classNames="swipe-marker">
        <div className={`swipe-marker ${className}`}></div>
      </CSSTransition>
    </div>
  )
}

const Default = ({ children, className }) => {
  return (
    <div className="w-full grid grid-cols-12">
      <div className="col-span-11 carousel-lg:col-span-12">
        <div className="relative aspect-w-303 aspect-h-742 md:aspect-w-616 md:aspect-h-742 carousel-lg:aspect-w-1436 carousel-lg:aspect-h-920">
          <div className="absolute inset-0 flex items-center carousel-lg:pr-20">
            <CursorMarker.Cursor className={className} />
          </div>
        </div>
      </div>
    </div>
  )
}

const Sales = ({ children, className }) => {
  return (
    <div className="w-full grid grid-cols-9">
      <div className="col-span-8">
        <div className="relative aspect-w-326 aspect-h-364 md:aspect-w-964 md:aspect-h-420">
          <div className="absolute inset-0 flex items-center">
            <CursorMarker.Cursor className={className} />
          </div>
        </div>
      </div>
    </div>
  )
}

const Print = ({ children, className }) => {
  return (
    <div className="w-full grid grid-cols-12">
      <div className="col-span-11 xl:col-span-12">
        <div className="aspect-w-303 aspect-h-368 md:aspect-w-616 md:aspect-h-742 lg:aspect-w-1436 lg:aspect-h-854">
          <div className="absolute inset-0 flex items-center">
            <CursorMarker.Cursor className={className} />
          </div>
        </div>
      </div>
    </div>
  )
}

const Gallery = ({ className }) => {
  return (
    <div className="w-full h-full grid grid-cols-12 gap-x-4">
      <div className="col-start-11 col-span-2 flex justify-center items-center">
        <div style={{ transform: "translateX(-3.4375rem)" }}>
          <CursorMarker.Cursor className={className} />
        </div>
      </div>
    </div>
  )
}

const Snapshot = ({ className }) => {
  return (
    <div className="container h-full flex lg:grid grid-cols-12 gap-x-4">
      <div className="ml-auto col-start-9 col-span-2 flex justify-center items-center">
        <div className="transform -translate-y-48 lg:-translate-y-28">
          <div style={{ transform: "translate(-3.4375rem)" }}>
            <CursorMarker.Cursor className={className} />
          </div>
        </div>
      </div>
    </div>
  )
}

const LatestPosts = ({ className }) => {
  return (
    <div className="container h-full flex">
      <div className="ml-auto flex justify-center items-center">
        <div>
          <CursorMarker.Cursor className={className} />
        </div>
      </div>
    </div>
  )
}

CursorMarker.Default = Default
CursorMarker.Snapshot = Snapshot
CursorMarker.Gallery = Gallery
CursorMarker.Print = Print
CursorMarker.LatestPosts = LatestPosts
CursorMarker.Cursor = Cursor
CursorMarker.Sales = Sales

import React from "react"
import ArrowDown from "@svg/ui/arrow-down.svg"

export const Arrow = ({ colorTop, colorBottom, fillColor }) => {
  return (
    <div data-aos="fade-up" data-aos-duration="500" className="z-20 relative container pointer-events-none">
      <div className="absolute inset-x-0 flex justify-center">
        <div
          className="grid grid-rows-2 min-h-115px md:min-h-135px lg:min-h-200px"
          style={{ width: "20px", transform: "translateY(-50%)" }}
        >
          <div className="flex justify-center">
            <div className={`${colorTop} h-full`} style={{ width: "2px" }}></div>
          </div>
          <div className="relative flex flex-col justify-center">
            <div className={`${colorBottom} h-full mx-auto`} style={{ width: "2px" }}></div>
            <div className="absolute inset-x-0 flex justify-center" style={{ bottom: "-1px" }}>
              <div className={`w-full fill-current ${fillColor}`}>
                <ArrowDown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

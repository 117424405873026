import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import ArrowRight from "@svg/ui/arrow-right.svg"

export const ButtonAction = ({ children, className, func }) => {
  const [isHovered, setHover] = useState(false)

  const clickHandler = () => {
    if (!func) {
      return
    }
    func()
  }
  return (
    <button
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => clickHandler()}
      className={`${className} font-bold flex items-center sm:hover:underline transition-all duration-300`}
    >
      <span>{children}</span>
      <CSSTransition in={isHovered} timeout={300} classNames="button-arrow">
        <div className="fill-current ml-2 flex" style={{ width: "20px" }}>
          <ArrowRight />
        </div>
      </CSSTransition>
    </button>
  )
}

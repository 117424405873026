import { useEffect, useState, useCallback } from "react"

export const useHeaderScroll = () => {
  const isBrowser = typeof window !== "undefined"
  const [isSmall, setSmall] = useState(false)
  const checkScrollPosition = useCallback(() => {
    if (!isBrowser) {
      return
    }
    setSmall(window.pageYOffset > 98)
  }, [isBrowser])

  useEffect(() => {
    window.addEventListener("scroll", checkScrollPosition)

    return () => window.removeEventListener("scroll", checkScrollPosition)
  }, [checkScrollPosition])

  return {
    isSmall,
  }
}

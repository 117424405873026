import { SubmitButton } from "@components/Button"
import React from "react"
import { useFormSettings } from "./useFormSettings"

export const GatedForm = ({ id, subject, recipient, fn = null }) => {
  const { register, formState, handleSubmit, onSubmit, formSubmitted } = useFormSettings({
    id,
    subject,
    recipient,
    fn,
  })
  const { errors } = formState

  return (
    <form id="gated-form" onSubmit={handleSubmit(onSubmit)} className="relative">
      <div className="grid gap-y-5">
        <div className="flex flex-col font-book text-4">
          <div className="flex items-center text-22">First name* </div>
          <div className={`relative field-group short mt-4 ${errors.first ? "error" : "clean"}`}>
            <input
              className="field-plain text-4 bg-gray-light"
              type="text"
              {...register("first", { required: true, maxLength: 80 })}
            />
          </div>
        </div>
        <div className="flex flex-col font-book text-4">
          <div className="flex items-center text-22">Last name* </div>
          <div className={`relative field-group short mt-4 ${errors.last ? "error" : "clean"}`}>
            <input
              className="field-plain text-4 bg-gray-light"
              type="text"
              {...register("last", { required: true, maxLength: 80 })}
            />
          </div>
        </div>
        <div className="flex flex-col font-book text-4">
          <div className="flex items-center text-22">Email* </div>
          <div className={`field-group short mt-4 ${errors.email ? "error" : "clean"}`}>
            <input
              className="field-plain text-4 bg-gray-light"
              type="email"
              {...register("email", {
                required: true,
                pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
            />
          </div>
        </div>
        <div className="flex flex-col font-book text-4">
          <div className="flex items-center text-22">Company* </div>
          <div className={`relative field-group short mt-4 ${errors.company ? "error" : "clean"}`}>
            <input
              className="field-plain text-4 bg-gray-light"
              type="text"
              {...register("company", { required: true, maxLength: 80 })}
            />
          </div>
        </div>
      </div>

      <div className="flex mt-12">
        <div className="flex">
          <SubmitButton
            className="p-5 bg-teal-light sm:hover:bg-white-dark"
            label="Get the guide"
            color={`text-gray-darkest ${formState.isDirty}`}
            formSubmitted={formSubmitted}
            disabled={formState.isSubmitting}
          />
        </div>
      </div>
    </form>
  )
}

import React from "react"
import PropTypes from "prop-types"
import { LogoClient } from "@components/UI"
import snapShotData from "@json/snapshots.json"

export const Snapshot = ({ client }) => {
  const { description, icon } = snapShotData[client]
  return (
    <div className="inline">
      <div className="relative overflow-hidden h-auto w-full">
        <div className="grid grid-cols-5 w-73 md:w-82 lg:w-147">
          <div className="col-span-4 flex flex-wrap">
            <div style={{ height: "115px" }}>
              <div style={{ height: `${icon.height}px` }}>
                <LogoClient slug={client} />
              </div>
            </div>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

Snapshot.propTypes = {
  client: PropTypes.string.isRequired,
}

import React from "react"

export const Print = ({ children }) => {
  return (
    <div className="w-full grid grid-cols-12 pointer-events-none">
      <div className="col-span-11 xl:col-span-12">
        <div className="aspect-w-303 aspect-h-368 md:aspect-w-616 md:aspect-h-742 lg:aspect-w-1436 lg:aspect-h-854">
          {children}
        </div>
      </div>
    </div>
  )
}

import React, { useContext, useEffect } from "react"
import PropTypes from "prop-types"
import AOS from "aos"
import { settingsContext } from "@context/provider"
import { Cursor } from "@components/Cursor"
import { Header } from "@components/Header"
import { GeneralForm } from "@components/Form"
import { Footer } from "@components/Footer"

export const Layout = ({ children }) => {
  const [settings] = useContext(settingsContext)
  const { generalForm } = settings

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "expo-ease-out",
    })
  }, [])

  return (
    <>
      <Cursor />
      <Header />
      <main>{children}</main>
      {generalForm.display && (
        <section className="pt-clamp-32-44">
          <div className="container flex xl:justify-center">
            <div className="xl:w-10/12">
              <div style={{ maxWidth: "365px" }}>
                <h2 data-aos="stagger" className="text-title">
                  <span>Let’s</span> <span>chat.</span>
                </h2>
              </div>
            </div>
          </div>
          <div className="container mt-24">
            <div className="xl:w-10/12 mx-auto">
              <GeneralForm />
            </div>
          </div>
        </section>
      )}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node,
}

import React from "react"

export const TextBlock = ({ editor }) => {
  return (
    <div className="lg:grid grid-cols-10 mt-clamp-18-22">
      <div className="col-start-2 col-span-8">
        <div className="article-text-block" dangerouslySetInnerHTML={{ __html: editor }} />
      </div>
    </div>
  )
}

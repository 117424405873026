import React, { useContext } from "react"
import { Link } from "gatsby"
import { settingsContext } from "@context/provider"
import { useHeaderScroll } from "./useHeaderScroll"
import { useMobileNav } from "./useMobileNavigation"
import LinkedIn from "@svg/social/linkedin.svg"
import Instagram from "@svg/social/instagram.svg"
import JkLogo from "@svg/jk-logo.svg"

export const HeaderSansNav = () => {
  const [settings] = useContext(settingsContext)
  const { navigation } = settings
  const { isSmall } = useHeaderScroll()
  const { isMobileOpen } = useMobileNav()

  return (
    <div className={`${navigation.color} overflow-hidden`}>
      <header className="fixed inset-x-0 top-0 z-60">
        <div
          className={`w-full transition-all duration-500 ${
            isSmall && !isMobileOpen ? navigation.backgroundColor : "bg-transparent"
          }`}
          style={{ padding: isSmall ? "1.25rem 0" : "1.75rem 0" }}
        >
          <div className="container flex items-center justify-between h-full">
            <div>
              <Link to="/">
                <JkLogo />
              </Link>
            </div>

            <div className="flex items-center mt-auto text-14 gap-x-4" style={{ height: "1.5rem" }}>
              <a
                href="https://linkedin.com/company/jk-design/mycompany/"
                target="_blank"
                rel="noreferrer"
                className="block text-white transition-all duration-300 fill-current sm:hover:text-teal"
              >
                <LinkedIn />
              </a>
              <a
                href="https://www.instagram.com/jk.design/"
                target="_blank"
                rel="noreferrer"
                className="block text-white transition-all duration-300 fill-current sm:hover:text-teal"
              >
                <Instagram />
              </a>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

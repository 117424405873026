import { useState } from "react"
import { useForm } from "react-hook-form"
import axios from "axios"

export const useFormSettings = ({ id, subject, recipient, fn }) => {
  const [formSubmitted, setFormSubmission] = useState(false)

  const { register, handleSubmit, formState, setValue } = useForm({ mode: "onChange" })

  const onSuccess = () => {
    if (typeof fn === "function") {
      fn()
    }
    setFormSubmission(true)
    setTimeout(() => {
      setFormSubmission(false)
    }, 12000)
  }

  const onSubmit = async (fData, e) => {
    const data = new FormData()
    for (const key in fData) {
      data.append(key, fData[key])
    }
    data.append("recipient", recipient)
    data.append("subject", subject)
    data.append("_wpcf7_unit_tag", id)

    try {
      await axios({
        url: `${process.env.GATSBY_WP_FORM}/${id}/feedback`,
        method: "POST",
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    } catch (error) {
      console.error(error)
    }

    try {
      onSuccess()
      e.target.reset()
    } catch (error) {
      throw error
    }
  }

  return {
    register,
    handleSubmit,
    formState,
    onSubmit,
    formSubmitted,
    setValue,
  }
}

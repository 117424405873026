import React, { useState } from "react"
import PropTypes from "prop-types"
import Vimeo from "@u-wave/react-vimeo"

export const VimeoPlayer = ({ children, id }) => {
  const [paused, setPause] = useState(true)

  return (
    <div className="absolute inset-0 overflow-hidden">
      {paused && (
        <>
          {children}
          <div className="absolute inset-0 flex justify-center items-center z-20">
            <button
              onClick={() => setPause(false)}
              className="bg-white rounded-full flex justify-center items-center w-20 h-20 lg:w-30 lg:h-30"
            >
              <span className="font-bold text-clamp-18-22">Play</span>
            </button>
          </div>
        </>
      )}

      <Vimeo video={id} controls={true} showTitle={false} responsive paused={paused} />
    </div>
  )
}
const Poster = ({ children }) => {
  return <div className="absolute inset-0 z-10">{children}</div>
}

VimeoPlayer.Poster = Poster

VimeoPlayer.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
}

Poster.propTypes = {
  children: PropTypes.node,
}

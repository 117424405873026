import React from "react"
import { Link } from "gatsby"

export const PillButton = ({ to = "", className = "bg-white-dark", staticButton = false, children }) => {
  if (!staticButton) {
    return (
      <Link
        to={to}
        className={`px-6 py-2 font-bold uppercase rounded-full text-gray-darkest hover:underline text-14 ${className}`}
      >
        {children}
      </Link>
    )
  }
  return (
    <div className={`px-6 py-2 font-bold uppercase rounded-full text-gray-darkest text-14 inline-block ${className}`}>
      {children}
    </div>
  )
}

import React from "react"
import { Link } from "gatsby"

export const Button = ({ buttonLink }) => {
  const { title, url, target } = buttonLink
  return (
    <div className="grid-cols-10 lg:grid mt-14">
      <div className="col-span-8 col-start-2">
        <div className="flex">
          {target === "_blank" && (
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2 px-6 py-4 border rounded-full border-gray-dark hover:underline"
            >
              <span className="font-bold text-14">{title}</span>
              <span>
                <ButtonIcon />
              </span>
            </a>
          )}
          {target !== "_blank" && (
            <Link
              to={url}
              className="flex items-center gap-2 px-6 py-4 border rounded-full border-gray-dark hover:underline"
            >
              <span className="font-bold text-14">{title}</span>{" "}
              <span>
                <ButtonIcon />
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

const ButtonIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_2731"
            dataName="Rectangle 2731"
            width="15"
            height="15"
            fill="none"
            stroke="#191919"
            strokeWidth="1.5"
          />
        </clipPath>
      </defs>
      <g id="Group_6131" dataName="Group 6131" clip-path="url(#clip-path)">
        <path
          id="Path_16238"
          dataName="Path 16238"
          d="M21.331.75h5.652V6.4"
          transform="translate(-13.106 0.372)"
          fill="none"
          stroke="#191919"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_636"
          dataName="Line 636"
          x1="6.806"
          y2="6.806"
          transform="translate(7.071 1.122)"
          fill="none"
          stroke="#191919"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_16239"
          dataName="Path 16239"
          d="M12.125,11.783v4.342H.75V4.75H5.092"
          transform="translate(0.372 -2.247)"
          fill="none"
          stroke="#191919"
          stroke-linecap="round"
          stroke-linejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  )
}

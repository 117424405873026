import { useState } from "react"
import { validateFileType } from "@utils"

export const useUpload = ({ name, setValue }) => {
  const [file, setFile] = useState({ set: false, name: "" })

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (
      !validateFileType(file, [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ])
    ) {
      return
    }

    setValue(name, file)
    setFile({ set: true, name: file.name })
  }

  const removeFile = () => {
    setValue(name, null)
    setFile({ set: false, name: "" })
  }

  return {
    file,
    handleFileChange,
    removeFile,
  }
}

import React, { useContext, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.min.css"
import { settingsContext } from "@context/provider"
import { Quote } from "./Quote"
import { Team } from "./Team"
import { Print } from "./Print"
import { MemoizedWork } from "./Work"
import { Sales } from "./Sales"
import { Snapshot } from "./Snapshot"
import { MemoizedFeaturedPost } from "./FeaturedPost"
import { MemoizedWebinarPost } from "./WebinarPost"

export const Carousel = ({ children, className = "" }) => {
  const [, dispatchSettings] = useContext(settingsContext)
  return (
    <div
      className={`${className} relative`}
      role="presentation"
      onMouseEnter={() => {
        dispatchSettings({ type: "SET_CURSOR", payload: { display: true, drag: false } })
      }}
      onMouseLeave={() => {
        dispatchSettings({ type: "SET_CURSOR", payload: { display: false, drag: false } })
      }}
    >
      {children}
    </div>
  )
}

const SwiperOverride = ({
  children,
  spaceBetween = 0,
  slidesPerView = "auto",
  centeredSlides = "true",
  breakpoints = {},
}) => {
  const [, dispatchSettings] = useContext(settingsContext)

  return (
    <Swiper
      onTouchStart={() => {
        dispatchSettings({ type: "SET_CURSOR", payload: { display: true, drag: true } })
      }}
      onTouchEnd={() => {
        dispatchSettings({ type: "SET_CURSOR", payload: { display: true, drag: false } })
      }}
      touchStartPreventDefault={false}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      centeredSlides={centeredSlides}
      breakpoints={breakpoints}
    >
      {children}
    </Swiper>
  )
}

export const SwiperWithPagination = ({
  children,
  spaceBetween = 0,
  slidesPerView = "auto",
  centeredSlides = "true",
  breakpoints = {},
}) => {
  const swiperRef = React.useRef(null)
  const [slide, setSlide] = useState(0)
  const [slideCount, setSlideCount] = useState(0)

  function nextButtonHandler() {
    swiperRef.current.swiper.slideNext()
    setSlide(swiperRef.current.swiper.activeIndex)
  }

  function prevButtonHandler() {
    swiperRef.current.swiper.slidePrev()
    setSlide(swiperRef.current.swiper.activeIndex)
  }

  return (
    <Swiper
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      centeredSlides={centeredSlides}
      breakpoints={breakpoints}
      ref={swiperRef}
      onSlideChange={() => setSlide(swiperRef.current.swiper.activeIndex)}
      onInit={(swiper) => {
        setSlideCount(swiper.slides.length)
      }}
    >
      {children}
      <div className="flex items-center justify-between mt-16">
        <div className="xl:pl-10">
          <span className="font-light text-white text-25 font-mongoose">
            0{slide + 1}-0{slideCount}
          </span>
        </div>
        <div className="flex gap-4 text-white">
          <button onClick={prevButtonHandler} className={slide === 0 ? "pointer-events-none" : ""}>
            <div
              style={{ borderRadius: "1rem" }}
              className={`${slide === 0 ? "opacity-50" : ""} flex items-center justify-center px-4 py-5 bg-teal-light`}
            >
              <svg
                style={{ transform: "rotate(180deg)" }}
                xmlns="http://www.w3.org/2000/svg"
                width="21.411"
                height="11.106"
                viewBox="0 0 21.411 11.106"
              >
                <g id="Group_5768" data-name="Group 5768" transform="translate(0 0.706)">
                  <g id="Arrow">
                    <line
                      id="Line_635"
                      data-name="Line 635"
                      x2="20"
                      transform="translate(0 4.848)"
                      fill="none"
                      stroke="#191919"
                      strokeWidth="2"
                    />
                    <path
                      id="Point"
                      d="M5586.469,1683.717l4.826,4.847-4.826,4.848"
                      transform="translate(-5571.295 -1683.717)"
                      fill="none"
                      stroke="#191919"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
          <button onClick={nextButtonHandler} className={slide === slideCount - 1 ? "pointer-events-none" : ""}>
            <div
              style={{ borderRadius: "1rem" }}
              className={`${
                slide === slideCount - 1 ? "opacity-50" : ""
              } flex items-center justify-center px-4 py-5 bg-teal-light`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="21.411" height="11.106" viewBox="0 0 21.411 11.106">
                <g id="Group_5768" data-name="Group 5768" transform="translate(0 0.706)">
                  <g id="Arrow">
                    <line
                      id="Line_635"
                      data-name="Line 635"
                      x2="20"
                      transform="translate(0 4.848)"
                      fill="none"
                      stroke="#191919"
                      strokeWidth="2"
                    />
                    <path
                      id="Point"
                      d="M5586.469,1683.717l4.826,4.847-4.826,4.848"
                      transform="translate(-5571.295 -1683.717)"
                      fill="none"
                      stroke="#191919"
                      strokeMiterlimit="10"
                      strokeWidth="2"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </Swiper>
  )
}

Carousel.Sales = Sales
Carousel.Swiper = SwiperOverride
Carousel.SwiperWithPagination = SwiperWithPagination
Carousel.Slide = SwiperSlide
Carousel.Quote = Quote
Carousel.Team = Team
Carousel.Work = MemoizedWork
Carousel.Snapshot = Snapshot
Carousel.Print = Print
Carousel.FeaturedPost = MemoizedFeaturedPost
Carousel.WebinarPost = MemoizedWebinarPost

import { useEffect, useReducer } from "react"

const actionTypes = {
  set_posts: "set_posts",
  show_more_posts: "show_more_posts",
  set_post_order: "set_post_order",
}

function blogReducer(state, action) {
  const { postsPerPage, postsToShow, next } = state
  switch (action.type) {
    case actionTypes.set_posts: {
      return {
        ...state,
        postsToShow: action.posts.slice(0, postsPerPage),
      }
    }
    case actionTypes.show_more_posts: {
      const toShow = action.posts.slice(postsToShow.length, state.next)
      return {
        ...state,
        postsToShow: [...postsToShow, ...toShow],
        next: next + postsPerPage,
      }
    }
    case actionTypes.set_post_order: {
      return {
        ...state,
        order: action.slug,
      }
    }
    default: {
      throw new Error(`Unhandled type in accordionReducer: ${action.type}`)
    }
  }
}

export const useBlog = ({ posts }) => {
  const initialState = {
    postsToShow: [],
    postsPerPage: 9,
    next: 12,
    order: "latest",
  }
  const [state, dispatch] = useReducer(blogReducer, initialState)
  const { postsToShow, order } = state

  useEffect(() => {
    dispatch({ type: actionTypes.set_posts, posts })
  }, [posts])

  const showMorePosts = () => {
    dispatch({ type: actionTypes.show_more_posts, posts })
  }

  const setPostOrder = (slug) => {
    if (order === slug) {
      return
    }
    posts.reverse()
    dispatch({ type: actionTypes.set_posts, posts })
    dispatch({ type: actionTypes.set_post_order, slug })
  }

  return { postsToShow, showMorePosts, setPostOrder }
}

import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { ButtonStatic } from "@components/Button"

const FeaturedPost = ({ post, index }) => {
  const { title, slug, date, excerpt, categories, article, featuredImage } = post.node || post

  return (
    <div className="grid w-full grid-cols-12">
      <Link
        to={`/blog/${slug}`}
        className="col-span-11 carousel-lg:col-span-12 carousel-lg:mr-20"
        style={{ cursor: "none" }}
      >
        <div className="aspect-w-303 aspect-h-742 md:aspect-w-616 md:aspect-h-742 carousel-lg:aspect-w-1436 carousel-lg:aspect-h-920">
          <div
            className={`flex ${
              index % 2 === 0 ? "flex-col" : "flex-col-reverse"
            } carousel-lg:flex carousel-lg:flex-row`}
          >
            <div
              className={`${article.theme} flex-1 relative py-16 sm:h-1/2 carousel-lg:h-full carousel-lg:w-1/2 overflow-hidden z-10`}
            >
              <div className="flex items-center justify-center w-full h-full md:h-full">
                <div className="flex flex-col w-10/12 h-full text-white lg:h-auto carousel-lg:w-8/12">
                  <p className="font-bold underline text-14">{categories.nodes[0].name}</p>
                  <h3 className="mt-6 text-clamp-20-32 md:hover:underline">{title}</h3>
                  {
                    article.truefalseDate ? null : <time dateTime={date} className="block mt-4 font-semi text-16 carousel-lg:mt-6">
                      {date}
                    </time>
                  }
                  <p className="text-clamp-14-18" style={{ marginTop: "1rem" }} dangerouslySetInnerHTML={{ __html: excerpt }} />
                  <div className="mt-auto md:mt-5 carousel-lg:mt-12">
                    <ButtonStatic className="text-white">Read more</ButtonStatic>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative w-full pointer-events-none aspect-w-303 aspect-h-254 md:aspect-none md:h-1/2 carousel-lg:h-full carousel-lg:w-1/2">
              <div className={`absolute inset-0 md:h-full ${article.theme}`}>
                {featuredImage !== null && (
                  <GatsbyImage className="w-full h-full" image={getImage(featuredImage.node.localFile)} alt={title} />
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export const MemoizedFeaturedPost = React.memo(FeaturedPost)

import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"
import ArrowRight from "@svg/ui/arrow-right.svg"
import Exit from "@svg/ui/exit.svg"

export const FileUploadButton = ({ label, file, removeFile }) => {
  const [isHovered, setHover] = useState(false)

  return (
    <div>
      {file.set ? (
        <div className="flex items-center font-semi text-pink">
          <button onClick={() => removeFile()} className="fill-current cursor-pointer" style={{ width: "12px" }}>
            <Exit />
          </button>
          <span className="ml-4">{file.name}</span>
        </div>
      ) : (
        <button
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          className={`flex items-center`}
        >
          <label
            htmlFor="resume"
            className={`${
              isHovered ? "text-gray-darkest sm:text-teal" : "text-gray-darkest"
            } cursor-pointer font-bold flex items-center transition-all duration-300`}
          >
            {label}
          </label>
          <label
            htmlFor="resume"
            className={`${
              isHovered ? "text-gray-darkest sm:text-teal" : "text-gray-darkest"
            } cursor-pointer font-book text-14 ml-2 leading-4 transition-all duration-300`}
          >
            (PDF, DOC, DOCX)
          </label>
          <CSSTransition in={isHovered} timeout={300} classNames="button-arrow">
            <div className="fill-current ml-2 flex" style={{ width: "20px" }}>
              <ArrowRight />
            </div>
          </CSSTransition>
          <input id="resume" name="resume" type="file" className="hidden" />
        </button>
      )}
    </div>
  )
}

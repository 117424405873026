import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { VimeoPlayer } from "@components/VimeoPlayer"

export const VideoBlock = ({ videoId, image, children }) => {
  return (
    <div className="light-background extend mt-clamp-18-22">
      <div className="absolute inset-0">
        <div className="aspect-w-1194 aspect-h-136 bg-white-dark extend before:bg-white-dark after:bg-white-dark z-10"></div>
      </div>
      <div className="lg:grid grid-cols-10">
        <div className="col-span-10 pb-24">
          <div className="aspect-w-1194 aspect-h-667 relative z-20">
            <VimeoPlayer id={videoId}>
              <VimeoPlayer.Poster>
                <div className="w-full h-full bg-gray-darkest">
                  {image !== null && (
                    <GatsbyImage className="w-full h-full" image={getImage(image.localFile)} alt="Video poster" />
                  )}
                </div>
              </VimeoPlayer.Poster>
            </VimeoPlayer>
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

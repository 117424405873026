import { graphql, useStaticQuery } from "gatsby"

import { useLocation } from "@reach/router"
import PropTypes from "prop-types"
import React from "react"
import { Helmet } from "react-helmet"

export const Seo = ({ title, description, metaImage = "/images/meta/jk.jpg", article }) => {
  const { pathname } = useLocation()
  const {
    site: { siteMetadata },
  } = useStaticQuery(query)
  const metaTitle = title ? `${title} | ${siteMetadata.title}` : siteMetadata.title
  const metaDescription = description || siteMetadata.description
  const metaLink = `${siteMetadata.siteUrl}${pathname}`

  return (
    <Helmet title={metaTitle}>
      <meta name="description" content={metaDescription} />
      {metaImage && <meta name="image" content={`${siteMetadata.siteUrl}${metaImage}`} />}
      {metaLink && <meta property="og:url" content={metaLink} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {metaTitle && <meta property="og:title" content={metaTitle} />}
      {metaDescription && <meta property="og:description" content={metaDescription} />}
      {metaImage && <meta property="og:image" content={`${siteMetadata.siteUrl}${metaImage}`} />}
    </Helmet>
  )
}

Seo.defaultProps = {
  article: false,
}

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.object,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`

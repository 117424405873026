import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { Link } from "gatsby"
import React from "react"

export const Post = ({ post }) => {
  const { slug, article, categories, title, date, featuredImage } = post
  const categoriesString = categories.nodes
    .map((cat, index) => {
      return index !== 0 ? ` ${cat.name}` : cat.name
    })
    .join()

  return (
    <Link to={`/ideas-and-insights/${slug}`} className="flex flex-col h-full post zoom" data-aos="fade-up">
      <div className="relative overflow-hidden aspect-w-468 aspect-h-202">
        <div className={`${article.theme} w-full h-full pointer-events-none absolute`}>
          {featuredImage !== null && featuredImage !== undefined && (
            <div className="zoom-image">
              <GatsbyImage
                className="w-full h-full"
                image={getImage(featuredImage.node.localFile)}
                loading="true"
                alt={title}
              />
            </div>
          )}
        </div>
      </div>
      <div className={`${article.theme} flex-grow pt-10 pb-14 px-12 text-white`}>
        <p className="underline font-semi text-14">{categoriesString}</p>
        <h2 className="mt-6 text-26 hover:underline">{title}</h2>
        {article.truefalseDate ? null : (
          <time dateTime={date} className="block mt-6 font-semi text-16">
            {date}
          </time>
        )}
      </div>
    </Link>
  )
}

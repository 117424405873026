import React from "react"

export const QuoteBlock = ({ quote, from }) => {
  return (
    <div className="grid-cols-10 article-text-block lg:grid mt-clamp-18-22">
      <div className="col-span-8 col-start-2">
        <p className="font-bold text-clamp-26-32 text-gray-darkest">
          &#8220;
          <span dangerouslySetInnerHTML={{ __html: quote }} />
          &#8221;
        </p>
        <p className="font-semi text-clamp-16-18" dangerouslySetInnerHTML={{ __html: from }} />
      </div>
    </div>
  )
}
